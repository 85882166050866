@import "../variables";

//
// ASIDE COMPONENT
//

// used for cart and login
.aside {
  background: $g-color-white;
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  left: auto;
  width: 100%;
  height: 100%;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  transform: translateX(100%);
  opacity: 0;
  transition: 0.5s transform ease-in-out, 10s opacity ease-in-out;
  overflow: auto;

  @media all and (min-width: $g-break-2) {
    max-width: 50%;
  }

  @media all and (min-width: $g-break-3) {
    max-width: 50rem;
  }
}

.aside--opened {
  transform: translateX(0);
  opacity: 1;
  transition: 0.5s transform ease-in-out, 0.1s opacity ease-in-out;
}

.aside__container {
  padding: 0rem 1rem;

  @media all and (min-width: $g-break-1) {
    padding: 0rem 10%;
  }

  @media all and (min-width: $g-break-2){
    padding: 0rem 12%;
  }
}