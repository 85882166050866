@import "../variables";

//
// PRODUCT DETAIL SECTION
//

.s-product-detail {

}

.s-product-detail__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  @media all and (min-width: $g-break-1) {
    flex-direction: row;
  }
}

.s-product-detail__info {
  flex: 0 1 50%;
  order: 2;

  @media all and (min-width: $g-break-1) {
    order: 1;
  }
}

.s-product-detail__image {
  flex: 0 1 45%;
  text-align: center;
  order: 1;
  margin-bottom: 2rem;

  @media all and (min-width: $g-break-1) {
    order: 2;
    margin-bottom: 0;
    margin-top: 1rem; // to be aligned with headline
  }

  img {
    max-width: 100%;
    max-height: 35rem;
  }
}

.s-product-detail__line {
  width: 100%;
  height: 0.125rem;
  margin: 2rem 0;
  background: $g-color-gray2;
}

.s-product-detail__info-text {
  font-size: 1.1rem;
  font-weight: 400;
  text-align: justify;
  line-height: 1.8;
}

.s-product-detail__button {
  margin: 4rem 0;
}