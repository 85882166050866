@import "../variables";

//
// LEAVES COMPONENT
// moving leaves

.leaves {
  position: relative;


  .leaves__item {
    position: absolute;
  }
}

.leaves__container {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  top: 0;
  left: 0;
  z-index: -1;

  display: none;

  @media all and (min-width: $g-break-1) {
    display: block;
  }
}

$base_left: 45%;
$base_top: 28%;
.leaves__item--1 {
  left: $base_left;
  top: $base_top;
}

.leaves__item--2 {
  left: $base_left + 5%;
  top: $base_top + 2%;
}

.leaves__item--3 {
  left: $base_left + 3.5%;
  top: $base_top + 7%;
}

.leaves__item--4 {
  left: $base_left - 4%;
  top: $base_top + 7%;
}

.leaves__item--5 {
  left: $base_left - 6%;
  top: $base_top + 12%;
}

.leaves__item--6 {
  left: $base_left +9%;
  top: $base_top + 15%;
}

.leaves__item--7 {
  left: $base_left + 3%;
  top: $base_top + 17%;
}