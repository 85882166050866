@import "../variables";

//
// TEXT COMPONENT
//

.text {
  line-height: 1.6;
  font-size: $g-size-14;
}

.text--formatted {
  h2 {
    margin-top: 1.5rem;
    font-size: 1.7rem;

    margin-bottom: 0.5rem;
  }

  h3 {
    font-size: 1.2rem;
    margin-top: 1.2rem;
    margin-bottom: 0.5rem;
  }

  h4 {
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 0.3rem;
  }

  a:not(.button) {
    @extend .link;
  }

  ol, ul {
    list-style-position: inside;
    margin: 2rem;

    li {
      margin: 1.3rem 0;
    }
  }
}

.text--p p + p {
  margin-top: 1em;
}

.text--light {
  font-weight: 300;
  letter-spacing: 0.05em;
}

.text--small {
  font-size: 0.85rem;
}

.text--justify {
  text-align: justify;
}

.text-medium {
  font-weight: 600;
}

.text-underline {
  text-decoration: underline;
}

.text-thicker-lines {
  line-height: 2;
}

.underlined-box {
  display: inline-block;
  position: relative;
  z-index: 3;

  span {
    $color: transparentize($g-color-green, 0.24);
    background-image: linear-gradient(to right, $color 0%, $color 100%);
    background-repeat: repeat-x; // make it horizontal
    background-position: 0 100%; // change the second value to adjust how far from the top the underline should be
    background-size: 100% 50%; // change the second value to the desired height of the underline
    transition: .3s all;
  }
}

.underlined-box-trigger:not(:hover):not(.underlined-box--active) .underlined-box--hover span {
  $color: transparentize($g-color-green-transparent, 0.3);
  background-position: 0 200%;
}

.underlined-box--light span {
  $color: transparentize($g-color-green, 0.5);
  background-image: linear-gradient(to right, $color 0%, $color 100%);
}

.underlined-box--white span {
  $color: transparentize($g-color-white, 0.6);
  background-image: linear-gradient(to right, $color 0%, $color 100%);
}

.text-regular {
  font-weight: normal;
}

.text-bold {
  font-weight: bold;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-thick {
  letter-spacing: 0.3rem;
}

.link {
  text-decoration: underline;
  position: relative;

 /* &::after, &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0.0625rem;
  }

  &::before {
    width: 100%;
    background: $g-color-gray3;
  }

  &::after {
    width: 0;
    background: $g-color-green-light;
    transition: .3s all;
  }

  &:hover::after{
    width: 100%;
  }*/
}

.link--black-green {
  color: $g-color-black;

  &:hover {
    color: $g-color-green;
  }
}

//
// TEXT SIZES
//

@each $key, $value in $g-text-sizes {
  .text-size-#{$key} {
    font-size: $value;
  }
  @media all and (min-width: $g-break-1) {
    .text-md-size-#{$key} {
      font-size: $value;

    }
  }
  @media all and (min-width: $g-break-1) {
    .text-lg-size-#{$key} {
      font-size: $value;

    }
  }
}

//
// TEXT COLORS
//

.text-white {
  color: $g-color-white;
}

.text-green {
  color: $g-color-green;
}

.text-blue {
  color: $g-color-blue;
}

.text-red {
  color: $g-color-red;
}

.text-gray-1 {
  color: $g-color-gray1;
}

.text-gray-2 {
  color: $g-color-gray2;
}

.text-gray-3 {
  color: $g-color-gray3;
}

.text-black {
  color: $g-color-black;
}