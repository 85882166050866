@import "../variables";

//
// CART, CART ASIDE
//

.cart {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    transition: .3s all;
    opacity: 0;
    pointer-events: none;
    z-index: 999;
  }
}

.cart--loading::after {
  opacity: 1;
}