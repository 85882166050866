@import "../variables";

//
// BOTTOM BAR
//

.bottom-bar {
  width: 100%;
}

.bottom-bar__central--shadow {
  z-index: 10;
  box-shadow: 0 0 2rem 1.2rem $g-color-white;
}

.bottom-bar__wrapper {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: $g-bar-height-small;
  z-index: 8;
  bottom: 0;

  .bottom-bar--normal & {
    background: $g-color-white;
    height: $g-bar-height-small;
    position: fixed;
  }

  @media all and (min-width: $g-break-1) {
    height: $g-bar-height;
  }
}

.bottom-bar__wrapper--small {
  height: $g-bar-height-small;
  background: $g-color-white;
}

.bottom-bar__left, .bottom-bar__right {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.bottom-bar__central {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;

  @media all and (min-width: $g-break-1) {
    background: $g-color-white;
    width: $g-container;
  }
}

.bottom-bar__central--page {
  padding-left: 8%; // depends on page__nav width!
  justify-content: space-between;
  width: 70%;
}

.bottom-bar__central--transparent {
  background: transparent;
}

.bottom-bar__nav {
  display: none;
  align-items: center;
  justify-content: space-around;
  justify-content: space-evenly; // for newer browsers
  height: 100%;
  width: 100%;
  padding: 0 1rem; // on the sides

  @media all and (min-width: $g-break-1) {
    display: flex;
  }
}

.bottom-bar__play-icon {
  font-size: 0.8rem;
  margin-top: -0.3rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}