@import "../variables";

//
// UTILITIES
//
// deprecated

.flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}