@import "../variables";

//
// FILE BOX COMPONENT
//

.file-box {
  display: inline-flex;
  margin-right: 2rem;
  padding: 0.5rem 1rem;
  border: 0.0625rem solid $g-color-gray2;
  transition: .3s all;

  &:hover {
    background: $g-color-gray2;
  }
}

.file-box .icon {
  margin-right: 0.5rem;
}

.file-box__name {
  font-size: 1rem;
  font-weight: 400;
}