@import "../variables";

//
// ICON BOX COMPONENT
// contains icon, headline and text
// text is shown after hover

.icon-box-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0.75rem 0;
  flex-wrap: wrap;

  @media all and (min-width: $g-break-1) {
    padding: 0;
    height: 12rem;
    flex-wrap: nowrap;
  }

  .icon-box {
    flex: 1 1 50%;

    @media all and (min-width: $g-break-1) {
      flex: 1 1 25%;
    }
  }
}

.icon-box-container--bordered {
  @media all and (min-width: $g-break-1) {
    .icon-box:not(:first-of-type) {
      border-left: 0.125rem solid $g-color-gray2;
    }
  }
}

.icon-box {
  padding: 1rem 0.2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media all and (min-width: $g-break-1) {
    padding: 1rem 0.5rem;
  }
}

.icon-box__icon {
  padding: 0 0 1.4rem;
  font-size: 1.2rem;
  line-height: 1;
  transition: .3s all;

  .icon-box--hover & {
    transform: translateY(2rem);
  }

  .icon-box--hover:hover & {
    transform: translateY(0);
  }
}

.icon-box__headline {
  transition: .3s all;

  .icon-box--hover & {
    transform: translateY(2rem);
  }

  .icon-box--hover:hover & {
    transform: translateY(0);
  }
}

.icon-box__text {
  padding-top: 0.5rem;
}

// For front-page categories
.icon-box--hover .icon-box__text {
  transform: translateY(50%);
  opacity: 0;
  transition: .3s all;
  padding-top: 0.5rem;
}

.icon-box--hover:hover .icon-box__text {
  opacity: 1;
  transform: translateY(0%);
}