@import "../variables";

//
// Price container component
//

.price-container {
  display: inline-block;
  position: relative;
  width: 100%;

  del {
    font-size: $g-size-8;
    margin-right: 1rem;

    @media all and (min-width: $g-break-1) {
      font-size: $g-size-10;
    }
  }

  ins, .woocommerce-Price-amount, .price-container__hover {
    text-decoration: none;
    font-size: $g-size-10;
    font-weight: 600;

    @media all and (min-width: $g-break-1) {
      font-size: $g-size-14;
    }
  }
}

.price-container--no-hover {
  width: auto;
}

.price-container__normal {
  transition: .2s all ease-out;
  width: 100%;

  .product-box:hover .price-container:not(.price-container--no-hover) & {
    opacity: 0;
  }

}

.price-container__hover {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  width: 100%;
  transition: .2s all ease-out;

  .product-box:hover & {
    opacity: 1;
  }
}

.price-container--sale {
  ins {
    color: $g-color-red;
  }
}

.price-container__arrow-icon {
  font-size: 0.7rem;
  margin-left: 0.5rem;
}