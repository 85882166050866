@charset "UTF-8";
@import "../variables";

//
// CUSTOM FONT ICON COMPONENT
// contains .dot
//

$path: 'fonts/ave-natura/';
@font-face {
  font-family: $g-font-icons;
  src: url($path + "avenatura.eot"),
  url($path + 'avenatura.eot?#iefix') format("embedded-opentype"),
  url($path + 'avenatura.woff') format("woff"),
  url($path + 'avenatura.ttf') format("truetype"),
  url($path + 'avenatura.svg#avenatura') format("svg");
  font-weight: normal;
  font-style: normal;
}

.icon {
  display: inline-flex;
  letter-spacing: 0;
  //line-height: 1;

  &::before {
    letter-spacing: 0;
  }
}

.icon--c-white {
  color: $g-color-white;
}

.icon--t-circle {
  width: 2.25rem;
  height: 2.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  background: $g-color-black;
  border: 0.0625rem solid $g-color-black;
}

.icon--t-circle-green {
  @extend .icon--t-circle;
  background: $g-color-green;
  border-color: $g-color-green;
}

// Used for content-switcher icon
.icon--t-circle2-hover {
  position: relative;
  $size: 14px;

  &::before,
  &::after {
    content: '';
    width: $size;
    height: $size;
    border-radius: $size;
    border: 2px solid $g-color-green;
    transition: .1s all;
    transition-timing-function: ease-in-out;
  }

  &::after {
    background: transparentize($g-color-green, 0.7);
    position: absolute;
    left: 0px;
    top: 0px;
    transform: scale(1);
    opacity: 0;
    z-index: -1;
    border-color: transparent;
  }

  .icon-hover-trigger:hover &::before {
    background: $g-color-green;
  }

  .content-switcher__nav-item--active &::after,
  .content-switcher__item--active .content-switcher__mobile-nav-item &::after,
  .is-active &::after {
    transform: scale(1.6);
    opacity: 1;
  }

  .content-switcher__nav-item--active &::before,
  .content-switcher__item--active .content-switcher__mobile-nav-item &::before,
  .is-active &::before {
    background: $g-color-green;
  }
}

.icon--t-circle-hover {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0);
    opacity: 0.8;
    width: 100%;
    height: 100%;
    background: transparentize($g-color-green, 0.6);
    z-index: -1;
    transition: .3s all;
  }

  .icon-hover-trigger:hover &::after {
    transform: translateY(-50%) translateX(-50%) scale(1.35);
    opacity: 1;
  }

  .content-switcher__nav-item--active &::after,
  .content-switcher__item--active .content-switcher__mobile-nav-item &::after {
    transform: translateY(-50%) translateX(-50%) scale(1.35);
  }

}

.icon:before {
  font-family: $g-font-icons !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  vertical-align: middle;
}

.icon--arrow-left:before {
  content: '\e000';
}

.icon--arrow-right:before {
  content: '\e000';
  transform: rotate(180deg);
}

.icon--arrow-up:before {
  content: '\e000';
  transform: rotate(-270deg);
}

.icon--arrow-down:before {
  content: '\e000';
  transform: rotate(-90deg);
}

.icon--fb:before {
  content: '\e001';
}

.icon--manual:before {
  content: '\e002';
}

.icon--photo:before {
  content: '\e003';
}

.icon--printer:before {
  content: '\e004';
}

.icon--triangle-down:before {
  content: '\e005';
}

.icon--triangle-left:before {
  content: '\e005';
  transform: rotate(-90deg);
}

.icon--cross:before {
  content: '\e016';
}

.icon--detail:before {
  content: '\e007';
}

.icon--pen:before {
  content: '\e008';
}

.icon--phone:before {
  content: '\e015';
  transform: scale(-1, 1);
}

.icon--pagelines:before {
  content: '\e00a';
}

.icon--hand-holding-heart:before {
  content: '\e00B';
}

.icon--pdf:before {
  content: '\e00c';
}

.icon--attachment:before {
  content: '\e00d';
}

.icon--heart:before {
  content: '\e00e';
}

.icon--drop:before {
  content: '\e006';
}

.icon--fingerprint:before {
  content: '\e00f';
}

.icon--cleaner:before {
  content: '\e010';
}

.icon--insta:before {
  content: '\e011';
}

.icon--truck:before {
  content: '\e012';
  transform: scale(-1, 1);
}

.icon--gift:before {
  content: '\e013';
}

.icon--delivery:before {
  content: '\e014';
}

.icon--chevron-up:before {
  content: '\e017';
}

.icon--chevron-left:before {
  content: '\e017';
  transform: rotate(-90deg);
}

.icon--chevron-right:before {
  content: '\e017';
  transform: rotate(90deg);
}

.icon--dot:before {
  content: '\e018';
}

.icon--chev-light-left:before {
  content: '\e019';
}

.icon--chev-light-right:before {
  content: '\e019';
  transform: rotate(180deg);
}

.icon--circle-thin:before {
  content: '\e01a';
}

// thin circle normally, dot on hover
.icon--circle-thin-hover:before {
  content: '\e01a';

  .icon-hover-trigger:hover &,
  .content-switcher__nav-item--active &,
  .content-switcher__item--active .content-switcher__mobile-nav-item & {
    animation-name: icon-circle-thin;
    animation-duration: .3s;
    animation-fill-mode: forwards;
    content: '\e018';
  }
}

.icon--tick:before {
  content: '\e01d';
}

.icon--plus:before {
  content: '\E01C';
}

.icon--mail:before {
  content: '\E01B';
}

.icon--cart:before {
  content: '\E01E';
}

.icon--globe:before{
  content: '\e00c';
}

.icon--arrow-long-left:before {
  content: '\e01f';
}

.icon--arrow-long-right:before {
  content: '\e01f';
  transform: rotate(180deg);
}

.icon--envelope:before {
  content: '\e020';
}