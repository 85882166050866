@import "../variables";

//
// TESTIMONIALS SECTION
//

.s-testimonials {

}

.s-testimonials__slider {
  padding-top: 2rem;
}

.s-testimonials__item {
  text-align: center;

  img {
    margin-bottom: 2.5rem;
    height: 3rem;
  }

  .text {
    font-style: italic;
    color: $g-color-gray3;
    max-width: 40rem;
    margin: 0 auto;
    font-size: $g-size-14;
  }
}