@import "../variables";

//
// ACCOUNT PAGE
//

.woocommerce-orders-table__row {

  td {
    border-bottom: 0.0625rem solid $g-color-gray2;
    padding: 1rem 0;
    font-size: $g-size-14;
    vertical-align: top;
  }
}

.woocommerce-orders-table__cell-order-actions {
  text-align: right;
}

.woocommerce-orders-table__cell-order-number {
  color: $g-color-green;
}

.woocommerce-pagination {
  margin-top: 3rem;
  justify-content: space-between !important;
}

// Used for details of order
.order-details-item {
  position: relative;
  font-size: $g-size-14;

  table {
    width: 100%;
  }

  td:first-of-type {
    color: $g-color-gray3;
    padding-right: 4rem;
  }

  td {
    vertical-align: top;
    padding: 0.3rem 0;
  }

  &:not(:last-of-type) {
    &::after {
      content: '';
      position: absolute;
      right: 0;
      height: 80%;
      width: 0.0625rem;
      background: $g-color-gray2;
      top: 50%;
      transform: translateY(-50%);
    }
  }

}