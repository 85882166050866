@import "../variables";

//
// PAGE CONTENT SECTION (page)
//

.s-page-content {

}

.s-page-content img {
  max-width: 100%;
  height: auto;
  margin-bottom: 2rem;

  @media all and (min-width: $g-break-1) {
    max-width: 50%;
  }
}

.s-page-content .alignleft {
  @media all and (min-width: $g-break-1) {
    float: left;
    margin-right: 5rem;
    margin-bottom: 1rem;
  }
}

.s-page-content .alignright {
  @media all and (min-width: $g-break-1) {
    float: right;
    margin-left: 5rem;
  }
}