@import "../variables";

//
// PRODUCT HEADER
// detail of a product
//

.s-product-header__wrapper {
  display: flex;
  flex-direction: column;

  @media all and (min-width: $g-break-1) {
    flex-direction: row;
  }

  img {
    max-width: 100%;
  }
}

.s-product-header__icons {
  display: none;

  img {
    margin: 0.5rem 0;
  }

  @media all and (min-width: $g-break-1) {
    display: flex;
    flex-direction: column;
    flex: 0 0 5rem;
  }
}

.s-product-header__photos {
  width: 100%;
  text-align: center;
  order: 2;
  position: relative;

  @media all and (min-width: $g-break-1) {
    order: inherit;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 auto;
  }
}

.s-product-header__photos-mobile {
  text-align: center;
  position: relative;
}



.s-product-header__text-container {
  order: 1;
  @media all and (min-width: $g-break-1) {
    order: inherit;
    flex: 0 1 45%;
  }
}

.s-product-header__variations {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}

.s-product-header__name {
  margin-bottom: 0.8rem;

  @media all and (min-width: $g-break-1) {
    margin-bottom: 1.5rem;
  }
}

.s-product-header__line {
  border-bottom: 0.125rem solid $g-color-gray2;
  margin: 1.2rem #{-$g-container-padding-large};

  @media all and (min-width: $g-break-1) {
    margin: 1.2rem 0;
  }
}