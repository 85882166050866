@import "../variables";

//
// CONTAINER
//

.container {
  margin: 0 auto;
  width: $g-container-large;

  @media all and (min-width: $g-break-1) {
    width: $g-container;
    max-width: 1400px;
  }
}

.container--larger {
  width: $g-container-large;
}

.container--md {
  width: 100%;

  @media all and (min-width: $g-break-1) {
    width: $g-container;
  }
}