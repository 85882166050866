//
// VARIABLES
//

$g-color-green: #5ABE76;
$g-color-green-light: #84CF99;
$g-color-green-transparent: rgb(95,174,116);
$g-color-white: #fff;
$g-color-red: #FF6F6F;
$g-color-blue: #5EB0F2;

$g-color-gray1: #5E5E5E; // text color
$g-color-gray2: #F0F1F2; // bg of footer
$g-color-gray3: #747B81; // text of footer
$g-color-gray4: #f0f0f0;
$g-color-gray5: #5D5D5D; // text of bubble
$g-color-black: #000000;
$g-color-border: #DADADA;
$g-color-text: $g-color-black;

//
// CONTAINER

$g-container: 85%;
$g-container-large: 90%;

$g-container-padding: (100% - $g-container) / 2;
$g-container-padding-large: (100% - $g-container-large) / 2;

$g-font-main: 'Avenir', sans-serif;
$g-font-icons: 'ave-natura';

$g-break-1: 768px;
$g-break-2: 1170px;
$g-break-3: 1600px;

$g-bar-height: 7rem;
$g-bar-height-small: 5rem;

$g-size-8: 0.625rem;
$g-size-10: 0.75rem;
$g-size-12: 0.875rem;
$g-size-14: 0.9375rem;
$g-size-16: 1rem;
$g-size-18: 1.25rem;
$g-size-20: 1.5rem;
$g-size-24: 1.75rem;
$g-size-30: 2rem;
$g-size-36: 2.5rem;
$g-size-96: 7.5rem;

$g-text-sizes: (
8: $g-size-8,
10: $g-size-10,
12: $g-size-12,
14: $g-size-14,
16: $g-size-16,
18: $g-size-18,
20: $g-size-20,
24: $g-size-24,
30: $g-size-30,
36: $g-size-36,
96: $g-size-96,
);
/*
$g-size-8: 8pt;
$g-size-10: 10pt;
$g-size-12: 12pt;
$g-size-14: 14pt;
$g-size-16: 16pt;
$g-size-18: 18pt;
$g-size-20: 20pt;
$g-size-24: 24pt;
$g-size-36: 36pt;*/
