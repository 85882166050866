@import "../variables";

//
// SINGLE PRODUCT PAGE
//

.p-single-product__content .line {

  @media all and (max-width: $g-break-1){
    margin: 1rem 0;
    border: none;
  }
}
