@import "../variables";

//
// LOGIN COMPONENT
//

.login {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 999;
  padding: 0rem 4rem 2rem;
  text-align: center;
  max-width: 38rem;
}

.login__headline {
  margin-bottom: 0.75rem;
}

.login__text {
  margin-bottom: 1rem;
}

.login__button {
  opacity: 0;
  width: 100%;
  transition: .3s all;
  pointer-events: none;

  .login--valid &{
    opacity: 1;
    pointer-events: initial;
  }
}

.login .form {
  display: inline-block;
}