@import "../variables";

//
// WOOCOMMERCE BASIC FILE
//

.woocommerce-message{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem $g-container-padding;
  background: $g-color-green-light;
  color: $g-color-white;
  flex-wrap: wrap;

  li {
    flex: 1 1 100%;
  }
}

.woocommerce-NoticeGroup {
  margin-bottom: 1rem;
}

.woocommerce-error {
  @extend .woocommerce-message;
  background: $g-color-red;
}

.woocommerce-info {
  @extend .woocommerce-message;
  background: $g-color-blue;
}


//
// PAYMENT METHODS
//-----------------

.wc_payment_methods {
  display: flex;
  flex-direction: column;
  list-style: none;

  @media all and (min-width: $g-break-1) {
    align-items: center;
    flex-direction: row;
  }
}

.wc_payment_method {
  margin: 1rem 0;
  white-space: nowrap;

  @media all and (min-width: $g-break-1) {
    margin: 0;

    &:not(:first-of-type) {
      margin-left: 4rem;
    }
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  input {
    visibility: hidden;
    position: absolute;
    opacity: 0;
  }
}

//
// LOGIN FORM
//-------------

.woocommerce-form-login {

}

.woocommerce-terms-and-conditions-checkbox-text a{
  display: inline;
  z-index: 90;
  position: relative;
}

.required {
  padding-left: 0.1rem !important;
  text-decoration: none;
}

.wc_payment_method img {
  transition: .3s all;
}

.wc_payment_method:not(.active) img {
  filter: grayscale(100%);
}

.wc_payment_method {
  transition: .3s all;

  &:not(.active) {
    color: $g-color-gray3;
  }

  &:not(.active):hover {
    color: $g-color-gray1;
  }
}

.woocommerce-terms-and-conditions {
  font-size: $g-size-12;

  @media all and (min-width: $g-break-1) {
    font-size: $g-size-14;
  }
}

.woocommerce-terms-and-conditions-link {
  text-decoration: underline;
}

.woocommerce-table, .woocommerce-orders-table {
  width: 100%;
  text-align: left;
  font-size: $g-size-14;
  border-collapse: collapse;

  th {
    font-weight: normal;
  }

  thead {
    color: $g-color-gray3;
  }

  td, th {
    padding: 1rem 0;
    border-bottom: 0.125rem solid $g-color-gray2;
  }

  .iteration {
    width: 20%;
  }
}

.product-submit {
  width: 100%;

  @media all and (min-width: $g-break-1) {
    width:  auto;
  }
}

//
// REMOVE BILLING ADDRESS 2 in checkout
//

#billing_address_2_field {
  display: none;
}

//
// PRICE