@import "../variables";

//
// VARIATION for product
//

.variation {
  display: flex;
  align-items: center;
}

.variation__image {
  filter: grayscale(100%);
  cursor: pointer;

  &:hover{
    filter: none;
  }

  img {
    height: 45px;
  }
}

.variation--active .variation__image,
.variation--active.variation__image{
  filter: none;
}

.variation__text {
  color: $g-color-gray3;

  .variation--active & {
    color: $g-color-text;
  }
}