@import "../variables";

//
// INTRODUCTION SECTION
// - home section

.s-introduction {
  color: $g-color-white;
  margin-top: 1rem;
  padding-bottom: 2rem;
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media all and (min-width: $g-break-1) {
    margin-top: 0;
    padding-bottom: 0;
  }
}


.s-introduction__main-headline {
  margin: 1rem 0 1rem;
}

.s-introduction__text-container {
  max-width: 55rem;
  margin: 0 auto;
  text-align: center;
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transform: translateY(0);

  @media all and (min-width: $g-break-1) {
    transform: none;
  }
}

.s-introduction__text-container-inner {
  transform: translateY(-20%);

  @media all and (min-width: $g-break-1) {
    transform: translateY(-40%);
  }
}

.s-introduction__text {
  line-height: 1.8rem;
  font-size: 1.1rem;
}

.s-introduction__button-container {
  margin-top: 2rem;

  @media all and (min-width: $g-break-1) {
    display: none;
  }
}

//
// PRODUCTS
//

.s-introduction__products {
  display: none;
  position: absolute;
  bottom: 0;

  @media all and (min-width: $g-break-1) {
    width: 100%;
    display: block;
  }
}

.s-introduction__products-header {
  padding: 2rem 0 4rem;
  text-align: center;
  cursor: pointer;
  transform: translateY(12rem);
  transition: .3s all;

  i {
    display: block;
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
  }

  .headline {
    transition: .3s all;
  }

  .p-home--categories-opened & {
    transform: translateY(0);

    i {
      animation-name: intro-products-icon-rotate;
      animation-duration: .3s;
      animation-fill-mode: forwards;
    }

    .headline {
      opacity: 0;
    }
  }
}

.s-introduction__products-main {
  transform: translateY(100%);
  overflow: hidden;
  transition: .3s all;
  position: relative;
  z-index: 5;

  &:before {
    z-index: -1;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 12rem + 4rem;
    background: rgba(0, 0, 0, 0.5);
    background-size: cover;
    filter: blur(20px);
  }

  .icon--heart,
  .icon--fingerprint,
  .icon--cleaner {
    padding-top: 0.1875rem;
    padding-left: 0.0625rem;
  }

}