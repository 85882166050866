@import "../variables";

//
// CHECKOUT PAGE
//

.p-checkout__form {
  .p-checkout__wrapper {
    padding-bottom: 3rem;
  }
}

.p-checkout .accordion__main{
  height: 100%;
}

.p-checkout__thankyou-bg {
  background: url('images/note-bg.svg');
  background-position: top;
  height: 5rem;
  background-size: cover;
  margin-top: 3rem;
  display: none;
  
  @media all and (min-width: $g-break-1) {
    display: block;
  }
}

.p-checkout__thankyou-info {
  order: 2;
  padding-bottom: 2rem;

  @media all and (min-width: $g-break-1) {
    order: initial;
    padding-bottom: 0rem;
  }
}

.p-checkout__thankyou-cart {
  order: 1;

  @media all and (min-width: $g-break-1) {
    order: initial;
  }
}