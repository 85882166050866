@import "../variables";

//
// BODY COMPONENT
//

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 16px;

  @media all and (min-width: $g-break-1) {
    font-size: 14px;
  }

  @media all and (min-width: $g-break-3) {
    font-size: 16px;
  }
}

body {
  min-height: 100%;
  height: 100%;
  font-family: $g-font-main;
  letter-spacing: 0.05em;
  line-height: 1.6;
}

html.main-nav-is-opened, html.main-nav-is-opened body{
  overflow: hidden;
}

.body-content {
  min-height: 100%;
  //transition: .3s all;
  animation-name: aside-fade-out;
  animation-duration: .5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  //filter: blur(0);
  //transform: scale(1) translateX(0);
}

.body-content::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: .3s opacity;
  z-index: 99;
}

body.aside-opened .body-content,
body.newsletter-opened .body-content{
  cursor: pointer;
  //filter: blur(15px);
  pointer-events: none;

  &::after {
    opacity: 1;
  }
}

body.page-template-template-blank {

  @media all and (min-width: $g-break-1){
    overflow: hidden;
  }
}

body.aside-opened {
  @media all and (min-width: $g-break-1) {
    overflow: hidden;
  }
}

::selection {
  background: transparentize($g-color-green-light, 0.3);
  color: $g-color-white;
}

a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
}

.clearfix {
  clear: both;
}

.a-inherit {
  color: inherit;
  text-decoration: none;
}

.first-section {
  //padding-top: $g-header-height-scrolled;

  @media all and (min-width: $g-break-2) {
    //padding-top: $g-header-height;
  }
}

.page-message {
  background: $g-color-green-light;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $g-color-white;
  min-height: 4rem;
  width: 100%;
  font-size: $g-size-10;

  @media all and (min-width: $g-break-1) {
    font-size: $g-size-12;
  }

  .button {
    display: none;
    margin-left: 1.75rem;

    @media all and (min-width: $g-break-1) {
      display: inline-block;
    }
  }
}

.cookies {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
}

.grecaptcha-badge {
  display: none;
}

.line {
  margin: 3rem 0;
  width: 100%;
  border-top: 0.125rem solid $g-color-gray2;
}

.image-100 {
  max-width: 100%;
  height: auto !important;
}

.bg-2 {
  background: $g-color-gray2;
}

// Cookies
#cookie-notice {
  background-color: rgba(0,0,0,0.7) !important;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #fff;
  //-webkit-box-shadow: inset 1px 1px 2px rgba(0,0,0,0.1);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  //-webkit-box-shadow: inset 1px 1px 2px rgba(0,0,0,0.2);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #c8c8c8;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:active {
  background: #a2a2a2;
  //-webkit-box-shadow: inset 1px 1px 2px rgba(0,0,0,0.3);
  border-radius: 5px;


}

.fancybox-bg {
  background: $g-color-white;
}

.fancybox-is-open .fancybox-bg {
  opacity: 1;
}

.fancybox-toolbar {
  right: 1%;
  opacity: 1;
}

.fancybox-button {

  background: transparent;
  color: $g-color-gray5;
  height: 70px;
  width: 70px;

  &:hover {
    color: $g-color-text;
  }
}


[data-fancybox] {
  outline: none;
}