@import "../variables";

//
// PRODUCT BOX
//
// used for showing products
// 3 types: 2 in row, 1 in row, 4 in row
$margin: 1.5%;

.product-box-container {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media all and (min-width: $g-break-1) {
    justify-content: flex-start;
  }
}

.product-box {
  background: $g-color-white;
  margin: $margin $margin;
  display: flex;
  align-items: stretch;
  transition: .3s all;

  &:hover {
    box-shadow: 6px 6px 14px 1px rgba(0, 0, 0, 0.07);
  }

  @media all and (min-width: $g-break-1) {
    margin: $margin $margin;
  }
}

.product-box--overlay {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    opacity: 0.5;
    pointer-events: none;
    z-index: 4; // under bubbles
    width: 100%;
    height: 100%;
  }
}

// Requires .product-box__body & .product-box__illustration
.product-box--type1 {
  flex: 0 0 calc(100% - #{$margin * 2});
}

// 2 in row
.product-box--type2 {
  flex: 0 1 calc(50% - #{$margin * 2});
}

.product-box--type3 {
  flex: 0 1 calc(50% - #{$margin * 2});
  text-align: center;

  @media all and (min-width: $g-break-1) {
    flex: 0 1 calc(50% - #{$margin * 2});
  }

  @media all and (min-width: $g-break-2) {
    flex: 0 1 calc(25% - #{$margin * 2});
  }
}

.product-box--type4 {
  flex: 0 1 calc(45% - #{$margin * 2});

  @media all and (min-width: $g-break-1) {
    margin: 0;
    background: $g-color-white;
  }
}

//
// ELEMENTS
//--------------

// Used for type1, type2 (image and text-container in row)
.product-box__body {
  $padding-0: 1rem 0.5rem 1rem;
  $g-padding-1: 2.8rem 1.5rem 2rem 1.5rem;
  $g-padding-2: 2.8rem 6rem 2rem 1.5rem;
  position: relative;
  padding: $padding-0;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media all and (min-width: $g-break-1) {
    padding: $g-padding-1;
    flex-direction: row;
    text-align: left;
  }

  @media all and (min-width: $g-break-2) {
    padding: $g-padding-2
  }


  .product-box--type1 & {
    padding: 2rem 0.5rem 2rem 0.5rem;

    @media all and (min-width: $g-break-1) {
      padding: $g-padding-1;
    }

    @media all and (min-width: $g-break-2) {
      flex: 0 1 50%; // for illustration
      padding: $g-padding-2;
    }
  }

  .product-box--type3 & {

    @media all and (min-width: $g-break-1) {
      text-align: center;
      flex-direction: column;
      padding: 2.2rem 1.5rem 1.5rem 1.5rem;
    }
    @media all and (min-width: $g-break-2) {
      padding: 2.2rem 1.5rem 1.5rem 1.5rem;

    }
  }

  .product-box--type4 & {
    @media all and (min-width: $g-break-1) {
      padding: 0.5rem 5% 0.5rem 5%;
    }
    @media all and (min-width: $g-break-2) {
      padding: 0.5rem 5% 0.5rem 5%;
    }
  }
}

.product-box__text-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 70%;

  /*.product-box--type1 & {
    @media all and (min-width: $g-break-1) {
      padding-right: 3rem;
    }
  }*/
}

.product-box__text {
  color: $g-color-gray3;
  padding-bottom: 0.5rem;
  padding-top: 0.7rem;
  flex: 1 0 auto;
  display: block;

  @media all and (min-width: $g-break-1) {
    padding-bottom: 0.5rem;
    padding-top: 1.5rem;
  }

  .product-box--type3 & {
    @media all and (min-width: $g-break-1) {
      padding-bottom: 0.2rem;
      padding-top: 0.2rem;
    }
  }

  .product-box--type4 & {
    @media all and (min-width: $g-break-1) {
      padding-bottom: 0.2rem;
      padding-top: 0.2rem;
    }
  }
}

// If product is variation then hide small description and price and show variations
.product-box__text-toggle {
  min-height: 5rem;
  display: flex;
  flex-direction: column;

  .product-box--variation & {
    animation-name: variationsFadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  @media all and (min-width: $g-break-1) {
    .product-box--variation.product-box--hover & {
      animation-name: variationsFadeOut;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
      display: none;
    }
  }

  .product-box--type1 &,
  .product-box--type2 & {
    min-height: 3rem;
  }

  @media all and (min-width: $g-break-1) {
    .product-box--type1 &,
    .product-box--type2 & {
      justify-content: flex-end;
    }
  }
}

.product-box__price-container {
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;

  @media all and (min-width: $g-break-1) {
    justify-content: flex-start;

    .product-box--type3 & {
      justify-content: center;
    }
  }
}

.product-box__price {
  font-size: $g-size-10;
  font-weight: bold;

  @media all and (min-width: $g-break-1) {
    font-size: $g-size-14;
  }
}

.product-box__price-discount {
  font-size: $g-size-10;
  margin-right: 1rem;
  color: $g-color-gray3;
  position: relative;

  @media all and (min-width: $g-break-1) {
    color: $g-color-text;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: $g-color-gray5;
    width: 108%;
    height: 0.0625rem;
    left: -4%;
  }
}

.product-box__bubbles {
  position: absolute;
  top: 1rem;
  left: 0;
  z-index: 20;
}

.product-box__illustration {
  display: none;
  overflow: hidden;

  @media all and (min-width: $g-break-2) {
    display: block;
    width: 50%;
  }

  // for picture itself
  div {
    background-size: cover;
    width: 100%;
    height: 100%;
    transition: .3s all;
  }

  .product-box:hover & div {
    //transform: scale(1.1, 1.1) translateX(1%);
  }
}

.product-box__thumb {
  text-align: center;
  overflow: hidden;
  flex: 1 0 auto;

  @media all and (min-width: $g-break-1) {
    flex: 0 0 auto;
    margin-right: 2rem;
    align-self: flex-end;
    margin-bottom: 0;
  }

  @media all and (min-width: $g-break-2) {
    width: 9rem;
  }

  img {
    max-height: 7rem;
    max-width: 100%;
    transition: .3s all;
    width: auto;
    height: auto;

    @media all and (min-width: $g-break-1) {
      max-height: 14rem;
    }
  }

  .product-box--type3 & {
    @media all and (min-width: $g-break-1) {
      flex: 0 0 auto;
      align-self: center;
      margin-bottom: 1rem;
    }
  }

  .product-box--type3 &,
  .product-box--type4 & {
    @media all and (min-width: $g-break-1) {
      margin-right: 0rem;
    }
  }

  .product-box--type4 & {

    @media all and(min-width: $g-break-1) {
      flex: 0 0 20%;
    }
  }
}

//
// VARIATIONS
//

.product-box__variations {
  padding-top: 0.5rem;
  display: none;
  animation-name: variationsFadeOut;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  min-height: 5rem;

  .product-box--type1 &,
  .product-box--type2 & {
    flex-direction: row;
    min-height: 3rem;
  }

  @media all and (min-width: $g-break-1) {
    .product-box--hover & {
      display: flex;

      animation-name: variationsFadeIn;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
    }
  }
}

.product-box__variations-images {
  display: flex;
  align-items: center;
  //justify-content: center;

  .product-box--type3 & {
    margin-bottom: 0.8rem;
  }

  .variation__image img {
    height: 30px;
  }
}