@import "../variables";

//
// NEWSLETTER
//

.newsletter {
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 1rem 2rem 2rem;
  background: $g-color-white;
  z-index: 87;
  text-align: center;
  box-shadow: 6px 6px 18px 2px rgba(0, 0, 0, 0.09);

  @media all and (min-width: $g-break-1) {
    padding: 2rem 5rem 3rem;
  }
}