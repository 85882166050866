@import "../variables";

//
// PAGE COMPONENT
//

.ave_page {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  color: $g-color-text;
}

.ave_page--blurred {

  &::after {
    content: '';
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: rgba(255,255,255,.7);
    z-index: 998;
  }
}

.ave_page.p-home {
  justify-content: flex-start;

  @media all and (min-width: $g-break-1) {
    justify-content: space-between;
  }
}

.ave_page__layout {
  flex: 1 1 auto;
  display: inline-flex;
  padding-top: 0;
  justify-content: center;
  margin-bottom: $g-bar-height-small + 2rem;

  @media all and (min-width: $g-break-1) {
    padding-top: 3rem;
  }
}

.ave_page__layout-wrapper {
  padding: 1rem 0 2rem;
  flex-direction: column;
  display: flex;
  width: 100%;

  @media all and (min-width: $g-break-1) {
    padding: 2rem 0;
  }

  @media all and (min-width: $g-break-2) {
    flex-direction: row;
    padding: 0;
  }
}

.ave_page__nav {
  padding-bottom: 2rem;

  @media all and (min-width: $g-break-2) {
    padding-right: 8rem;
    width: 20%;
  }
}

.ave_page__content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  @media all and (min-width: $g-break-2) {
    width: $g-container;
  }
}

.ave_page__content--smaller {
  max-width: 80rem;
}

.ave_page__scrollbar-space {
  margin-right: 2rem;

  @media all and (min-width: $g-break-2) {
    margin-right: 10rem;
  }
}

.ave_page__scrollbar-space--small {
  @media all and (min-width: $g-break-2) {
    margin-right: 4rem;
  }
}