@import "../variables";

//
// FORM COMPONENT
//

.form, .wpcf7-form {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}

.form-row {
  margin: 1.4rem 0;
  position: relative;
  //flex: 0 1 100%;
}

.input-text {
  font: inherit;
  font-size: $g-size-16;
  padding: 0.8rem 0.5rem 0.3rem 0rem;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid $g-color-gray2;
  font-weight: 600;

  &::placeholder {
    color: transparent;
  }

  &:focus {
    outline: none;
  }
}

.form-row label:focus {
  outline: none;
}

/* LABEL ======================================= */
.input-text ~ label {
  color: $g-color-gray3;
  font-size: $g-size-12;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: 0.625rem;
  left: 0;
  transition: 0.3s ease all;
}

/* active state */
.input-text:focus ~ label, .input-text.input-text-inside ~ label {
  top: -0.625rem;
  font-size: $g-size-10;
}

.input-text:focus ~ label {
  color: $g-color-green;
}

// BOTTOM BAR of input
//-------------
.input-bar {
  position: relative;
  display: block;
  width: 100%;
}

.input-bar:before, .input-bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: $g-color-green-light;
  transition: 0.3s ease all;
}

.input-bar:before {
  left: 0;
}

/* active state */
.input-text:focus ~ .input-bar:before{
  width: 100%;
}

.checkbox {
  $square-size: 1rem;
  color: $g-color-text;
  position: relative;
  font-size: $g-size-12;
  font-weight: bold;
  margin: 0.5rem 0;

  .link {
    z-index: 10;
    position: relative;
  }

  input {
    opacity: 0;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 3;
  }

  .wpcf7-list-item-label, .checkbox__label, label, > span {
    padding-left: $square-size + 1rem;

    // square
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: -0.1rem;
      width: $square-size;
      height: $square-size;
      border: 0.125rem solid $g-color-gray2;
      transition: .1s all;
    }

    &::before {
      @extend .icon--tick:before;
      font-family: $g-font-icons;
      position: absolute;
      top: 0rem;
      left: 0.3rem;
      font-size: 0.7rem;
      transform: scale(0);
      color: $g-color-text;
    }
  }

  input:focus, input:hover {
    & ~ .wpcf7-list-item-label,
    & ~ .checkbox__label,
    & ~ span,
    & ~ label {
      &::after {
        border-color: $g-color-green;
      }
    }
  }

  input:checked {
    & ~ .wpcf7-list-item-label,
    & ~ .checkbox__label,
    & ~ span,
    & ~ label {
      &::after {
       // border-color: $g-color-gray3;
      }
      &::before {
        transform: scale(1);
      }
    }
  }
}

.input-group select ~ label {
  display: none;
}

.select2-selection {
  border: none !important;
  outline: none !important;
  font-size: $g-size-14;
  color: $g-color-black;
  font-family: inherit;
  font-weight: bold;
  border-bottom: 0.125rem solid $g-color-gray2 !important;

  .select2-selection__rendered {
    padding-left: 0 !important;
  }
}

.select2-container--focus,
.select2-container--open {
  ~ .input-bar:before,
  ~ .input-bar:after {
    width: 50%;
  }
}

.select2-container .select2-selection--single {
  height: auto !important;
  margin: 0 !important;
}

.select2-selection__rendered{
 color: $g-color-text !important;
}

//
// INPUT NORMAL
// normal input with border
//

.input-normal {
  padding: 0.8rem 1.2rem;
  border: 0.0625rem solid $g-color-gray2;
  color: $g-color-text;
  font: inherit;
  font-size: $g-size-14;

  &:focus {
    border-color: $g-color-green;
    outline: none;
  }

  &::placeholder {
    color: $g-color-gray3;
  }
}

// Used for info box in the input
.input-wrapper {
  position: relative;

  .input-notice {
    $size: 1.5rem;
    position: absolute;
    top: 50%;
    right: 1rem;
    background: $g-color-green;
    color: $g-color-white;
    width: $size;
    height: $size;
    border-radius: $size;
    line-height: 0;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $g-size-12;
    margin-top: -($size/2);
  }
}
