@import "../variables";

//
// PRODUCT LIST
//

.product-list__item {
  display: flex;
  align-items: flex-start;
  border-bottom: 0.125rem solid $g-color-gray2;
  margin-bottom: 1.25rem;
  padding: 0.25rem 0;
  min-height: 5rem;
}

.product-list__item-text-container{
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.product-list__item-thumb {
  text-align: center;
  width: 7rem;

  img {
    max-height: 100%;
    height: 60px;
    width: auto;
  }
}

.product-list__quantity {
  display: flex;
  justify-content: flex-end;
}