@import "../variables";

//
// HEADLINE COMPONENT
//

.headline {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.2rem;

  h1, h2, h3, h4, h5, h6 {
    font: inherit;
  }
}

// Small headline used at introduction page (with green underline)
.headline--type1 {
  text-transform: uppercase;
  font-size: $g-size-10;
  font-weight: 700;
  letter-spacing: 0.12em;

  @media all and (min-width: $g-break-1) {
    font-size: $g-size-14;
  }
}

// Used for main headline at introduction page
.headline--type2 {
  letter-spacing: 0.05em;
  font-size: $g-size-24;
  text-transform: none;

  @media all and (min-width: $g-break-1) {
    font-size: $g-size-36;
  }
}

// Used for store
.headline--type3 {
  font-weight: bold;
  font-size: $g-size-18;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin-bottom: 1.5rem;

  @media all and (min-width: $g-break-1) {
    font-size: $g-size-20;
    margin-bottom: 1.5rem;
  }
}

// Used for icon-boxes
.headline--type4 {
  font-size: $g-size-8;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  @media all and (min-width: $g-break-1) {
    font-size: $g-size-12;
  }
}

// Product box type1,2
.headline--type5 {
  font-weight: bold;
  font-size: $g-size-18;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

// Product box type3
.headline--type6 {
  font-weight: bold;
  font-size: $g-size-14;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

//
// HEADLINE LINE
//-----------------

.headline-line {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;

  &:after, &:before{
    content: '';
    height: 0.125rem;
    background: $g-color-gray2;
    z-index: 3;
    flex: 1 1 auto;
  }

  .headline-line__text {
    flex: 0 1 auto;
    margin: 0 2rem;
  }
}

