@import "../variables";

//
// BUBBLE
//

.bubble {
  font-size: $g-size-12;
  color: $g-color-white;
  background: $g-color-gray5;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $g-size-10;
  padding: 0.5rem 0.8rem;
  font-weight: bold;
}

.bubble--smaller {
  @media all and (min-width: $g-break-1) {
    font-size: $g-size-10;
  }
}

.bubble--red {
  background: $g-color-red;
}

.bubble--blue {
  background: $g-color-blue;
}

