@import "../variables";

//
// TABLE
//

.table {
  width: 100%;
}

// only two
.table--space-between {
  td:nth-of-type(2) {
    text-align: right;
  }
}

.table__row-bordered {
  td {
    border-top: 0.125rem solid $g-color-gray2;
    border-bottom: 0.125rem solid $g-color-gray2;
  }
}