@import "../variables";

//
// CONTENT SWITCHER
// used for opening and closing parts of web
//

.content-switcher__nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;

}

.content-switcher__nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  flex: 1 1 auto;
  text-align: center;

  &:not(:nth-of-type(1)){
    border-left: 0.125rem solid $g-color-gray2;
  }
}

.content-switcher__mobile-nav-item {
  display: block;
  //border-bottom: 0.125rem solid $g-color-gray2;

  @media all and (min-width: $g-break-1) {
    display: none;
  }

  position: relative;
}

.content-switcher__nav--bordered {
  border-bottom: 0.125rem solid $g-color-gray2;
  border-top: 0.125rem solid $g-color-gray2;
}

.content-switcher__nav--bottom-border {
  border-bottom: 0.125rem solid $g-color-gray2;
}