@import "../variables";

/* Slider */
.slick-slider
{
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list
{
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus
{
  outline: none;
}
.slick-list.dragging
{
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track
{
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
  display: table;

  content: '';
}
.slick-track:after
{
  clear: both;
}
.slick-loading .slick-track
{
  visibility: hidden;
}

.slick-slide
{
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide
{
  float: right;
}
.slick-slide.slick-loading img
{
  display: none;
}
.slick-slide.dragging img
{
  pointer-events: none;
}
.slick-initialized .slick-slide
{
  display: block;
}
.slick-loading .slick-slide
{
  visibility: hidden;
}
.slick-vertical .slick-slide
{
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

//
// Custom editing:
//-----------------

.slick-dots {
  text-align: center;
  display: flex;
  justify-content: center;
  list-style: none;
  margin-top: 3rem;

  li button {
    $size: 0.4rem;
    display: inline-block;
    margin: 0 0.3rem;
    font-size: 0;
    width: $size;
    height: $size;
    border-radius: $size;
    cursor: pointer;
    background: $g-color-gray2;
    transition: .3s all;

    @media all and (min-width: $g-break-1) {
      $size: 0.7rem;
      width: $size;
      height: $size;
      border-radius: $size;
      margin: 0 0.6rem;
    }
  }

  li button:focus {
    outline: none;
  }

  li.slick-active button, li button:hover {
      background: $g-color-black;
  }
}

.slick-arrows-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
}