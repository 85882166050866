@import "../variables";

//
// LANG CHANGE PAGE
// used only in ave-natura-care folder
//

.p-lang-change {
  position: relative;
  display: flex;
  align-items: stretch;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.p-lang-change__wrapper {
  background: $g-color-white;
  position: relative;
  z-index: 3;
  margin-top: 4rem;

  @media all and (min-width: $g-break-1) {
    margin-top: 6rem;
  }
}

.p-lang-change__map {
  background: url('images/world.svg') no-repeat;
  //background-size: auto 100%;
  background-position: top center;
  width: 90%;
  margin: 0 auto;

  @media all and (min-width: $g-break-1) {
    width: 80%;
    min-height: 15rem;
  }
}

.p-lang-change__map-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  margin-top: 1rem;
}

.p-lang-change__item {
  
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem 2.5rem 0.5rem 0.5rem;
  transition: .3s all;
  text-align: left;
  background: transparent;
  white-space: nowrap;

  &:hover {
    background: $g-color-white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
}