@import "../variables";

//
// FOOTER COMPONENT
//

.footer {
  font-size: $g-size-12;
}

.footer__top {
  padding: 1rem 0 0;
  background: $g-color-gray2;

  @media all and (min-width: $g-break-1) {
    padding: 5rem 0;
  }
}

.footer__wrapper {



  @media all and (min-width: $g-break-1) {
    padding: 0 $g-container-padding;
    display: flex;
    justify-content: space-between;
  }
}

.footer__part {

  @media all and (min-width: $g-break-1) {
    display: flex;
    align-items: flex-start;

  }
}

.footer__col {
  margin: 0;
  padding: 1rem $g-container-padding;
  border-bottom: 0.0625rem solid $g-color-border;
  display: flex;
  flex-wrap: wrap;

  @media all and (min-width: $g-break-1) {
    padding: 0;
    border: 0;

    &:not(:first-of-type) {
      margin-left: 4rem;
    }
  }

  ul {
    list-style: none;
    width: 100%;
  }

  a {
    height: 100%;
    display: flex;
  }
}

.footer__col--socials {
  padding-bottom: 3rem;
  justify-content: space-between;

  @media all and (min-width: $g-break-1) {
    justify-content: inherit;
    padding-bottom: 0;
  }
}

.footer__col-item, .footer__col li {
  padding: 0.65rem 0;
  display: block;

  @media all and (min-width: $g-break-1) {
    padding: 0.55rem 0;
  }
}

.footer__col-item, .footer__col a {
  display: flex;
  justify-content: space-between;

  .footer__col-item-icon {

    @media all and (min-width: $g-break-1) {
      display: none;
    }
  }
}

.footer__icon-item {
  display: flex;
  align-items: center;
  font-size: $g-size-14;
  color: $g-color-black;
  margin-bottom: 0.5rem;

  .item-image {
    position: relative;
  }

  &:hover .item-image-hover {
    opacity: 1;
  }

  .item-image-hover {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: .3s all;
  }

  .item-image {
    margin-right: 0.5rem;
  }

  &:not(:first-of-type) {
    margin-left: 0rem;

    @media all and (min-width: $g-break-1) {
      margin-left: 4rem;
    }
  }
}

.footer__bottom-text {
  font-size: $g-size-10;
  margin-top: 3rem;

  @media all and (min-width: $g-break-1) {
    margin-top: 0;
  }
}

.footer__bottom {
  text-align: center;
}

.footer__language {
  padding: 0.65rem 0;

  @media all and (min-width: $g-break-1) {
    padding: 0.55rem 0;
  }
}