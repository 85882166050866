@import "../variables";

//
// HOME PAGE
//

.p-home {
  min-height: 100vh;

  @media all and (min-width: $g-break-1) {
    min-height: 41rem;
    height: 100vh;
  }
}

.p-home__bg {
  position: absolute;
  width: 108%;
  height: 108%;
  top: -4%;
  left: -4%;
  background: url('images/bg2-1.jpg') no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
  transition: .3s all;

  @media all and (min-width: $g-break-1) {
    background-position: top left;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.25);
    pointer-events: none;

    @media all and (min-width: $g-break-1) {
      background: transparent;
    }
  }
}

.p-home__bg--normal {
  .p-home--categories-opened & {
    //height: calc(100% - 12rem); // minus categories height
  }
}

.p-home__bg--blur {
  filter: blur(20px);
  z-index: -2;
  top: auto;
  bottom: -5rem;
  height: 12rem + 8rem;

  &:after {
    background: rgba(0,0,0,0.35);
  }
}