@import "../variables";

//
// IMAGE COMPONENT
//

.image {
  display: block;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    z-index: 2;
    transition: .3s all;
    width: 100%;
    height: 100%;
  }

  &:hover::after {
    background: rgba(255,255,255,0.4);
  }
}

.image__img {
  z-index: 1;
  max-width: 100%;

  .image:hover & {
    filter: blur(1px);
  }
}

.image__hover {
  opacity: 0;
  transition: .3s all;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  z-index: 3;

  .image:hover & {
    opacity: 1;
  }
}

.image__hover--bottom {
  bottom: 0;
  left: 0;
  top: auto;
  transform: none;
}