@import "../variables";

//
// ACCORDION COMPONENT
// .accordion--native is for checkout page

// Root component
.accordion {

}

.accordion--native{

}

// Item
.accordion__item {
  //display: flex;

  padding: 0 0 1.5rem;
  border-bottom: 0.125rem solid $g-color-gray2;


  .accordion--native & {
    padding: 0;
    border-bottom: none;
  }
}

.accordion__header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 1.5rem;

  .accordion--native &{
    padding-top: 0;
  }
}

.accordion__header-icon {
  margin-left: auto;
  transition: .3s all;

  .accordion__item--opened & {
    transform: rotate(135deg);
  }
}

.accordion__number {
  font-size: $g-size-14;
  font-weight: bold;
  min-width: 2.2rem;

  @media all and (min-width: $g-break-1) {
    width: 5%;
  }
}

.accordion__icon {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 90%;
  border: 0.0625rem solid $g-color-gray3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $g-color-text;
  margin-right: 1rem;
  transition: .3s all;

  .accordion__item--opened & {
    color: $g-color-white;
    background: $g-color-text;
    border-color: $g-color-text;
  }

  .accordion__header:hover & {
    border-color: $g-color-text;
  }
}

.accordion__headline {
  transition: .3s all;
}

.accordion__main {
  padding-top: 0.7rem;
  padding-left: 2.2rem;
  max-width: 60rem;

  @media all and (min-width: $g-break-1) {
    padding-left: 5%;
  }

  .accordion--native & {
    padding-top: 0;
    padding-left: 0;
    max-width: none;

    @media all and (min-width: $g-break-1) {
      padding-left: 0;
    }
  }

}