@import "../variables";

//
// CART ICON
// used in top bar
//

.cart-icon {
  position: relative;
}

.cart-icon__number {
  $desktop-size: 1.75rem;
  width: $desktop-size;
  height: $desktop-size;
  border-radius: $desktop-size;
  background: $g-color-green;
  color: $g-color-white;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  letter-spacing: 0; // to make sure number is in the center
}

.cart-icon--mobile .cart-icon__number {
  position: absolute;
  right: -0.7rem;
  top: -0.7rem;

  font-size: $g-size-10;
  $mobile-size: 1.3rem;
  width: $mobile-size;
  height: $mobile-size;
  border-radius: $mobile-size;
}