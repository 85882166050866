@import "../variables";

//
// LANG SWITCH
//

.lang-switch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  transition: .3s opacity;
  position: relative;
}

.lang-switch__visible {
  display: flex;
  align-items: center;

  .icon {
    font-size: 0.5rem;
    margin-left: 0.4rem;
  }
}

.lang-switch__lang {
  font-weight: 400;
  padding: 0rem 0.5rem;
  transition: .3s all;

  .lang-switch--opened & {
    padding: 0.8rem 0.5rem;
  }

  &.lang-switch__visible, &:hover {
    font-weight: 700;
  }
}

.lang-switch__other {
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 1.3rem;
  transform: translateY(-100%);

  @media all and (min-width: $g-break-1) {
    top: 2.1rem;
  }
}