@import "../variables";

//
// ERROR PAGE
//

.p-error-page {

}

.p-error-page__text-container {
  display: flex;
  align-items: center;
}

.p-error-page__error-headline {
  font-size: $g-size-96;
  position: absolute;
  font-weight: bold;
  width: 100%;
  color: $g-color-gray2;
  z-index: -1;
}