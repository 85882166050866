@import "../variables";

//
// PRODUCT TOOLBAR
//

.product-toolbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.2rem 0;
  background: $g-color-white;
  z-index: 999;
  border-top: 0.125rem solid $g-color-gray4;
  border-bottom: 0.125rem solid $g-color-gray4;
  font-size: $g-size-10;
  transition: .2s all;
  transform: translateY(100%);
  pointer-events: none;

  @media all and (min-width: $g-break-1) {
    bottom: auto;
    top: 0;
    transform: translateY(-100%);
  }

  .row {
    justify-content: space-between;
  }

  &--opened {
    pointer-events: inherit;
  }
}