@import "../variables";

//
// CONTACT PAGE
//

.p-contact {

}

// line with icon, text and description
.contact-row {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  flex-direction: column;

  @media all and (min-width: $g-break-1) {
    flex-direction: row;
  }

  .icon {
    margin-right: 0.2rem;
    color: $g-color-green;
    width: 1.2rem;
    font-size: $g-size-18;
  }

  > div {
    flex: 0 1 50%;
    display: flex;
    align-items: center;
  }

  div:nth-of-type(1) {
    color: $g-color-text;
  }

  div:nth-of-type(2) {
    color: $g-color-gray3;
  }
}