@import "../variables";

//
// CATEGORY NAv COMPONENT
//

.category-nav {
  padding-bottom: 1rem;
  margin-bottom: 3rem;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0.125rem;
    background: $g-color-gray2;
    left: 0;
    animation-name: category-nav-line;
    animation-duration: 0.75s;
    animation-delay: 0.3s;
    animation-timing-function: cubic-bezier(0.75, 0.17, 0.44, 1);
    animation-fill-mode: forwards;
  }
}

.category-nav__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  justify-content: space-evenly;
  flex-direction: column;

  @media all and (min-width: $g-break-1) {
    flex-direction: row;
  }
}

.category-nav__item {
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  font-weight: 700;
}

//.category-nav__line {
  //width: 0;
  //height: 0.4rem;
  //background: transparentize($g-color-green-transparent, 0.3);
  //transition: .3s transform, .3s left;
  //position: absolute;
  //left: 0;
  //bottom: 1.6rem;
//}