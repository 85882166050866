@import "../variables";

//
// NAV ITEM
//

.nav-item {
  text-align: center;
}

.nav-item__headline {
  text-transform: uppercase;
  font-size: 1.05rem;
  font-weight: 700;

  @media all and (min-width: $g-break-3) {
    font-size: 0.95rem;
  }
}

.nav-item__text,
.nav-item__text-sizer {
  font-weight: 500;
  overflow: hidden;
  opacity: 0;
  letter-spacing: 0.02em;
  font-size: 0.95rem;
  max-height: 0.01px;
}

.nav-item__text {
  transition: .3s all;
  transform: translateY(50%);

  .nav-item:hover & {
    max-height: 2rem;
    opacity: 1;
    transform: translateY(0);
    padding-top: 0.3rem;
  }
}