@import "../variables";

//
// TOP BAR
//

.top-bar {
  width: 100%;
  flex: 0 0 1;
}

.top-bar--normal {

  @media all and (min-width: $g-break-1) {
    margin-bottom: 5rem;
  }
}

.top-bar--custom {
  @media all and (min-width: $g-break-1) {
    margin-bottom: 2rem;
  }
}

.top-bar--bg {
  background: url('images/nav_bg.png') no-repeat;
  flex: 0 0 9rem;
  background-size: cover;

  @media all and (min-width: $g-break-1) {
    background-size: 100% 100%;
  }
}

.top-bar__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

.top-bar__box {
  display: flex;
  align-items: center;
  height: $g-bar-height-small;
  cursor: pointer;
  flex: 1;


  @media all and (min-width: $g-break-1) {
    height: $g-bar-height;
  }

  .top-bar--smaller & {
    height: $g-bar-height-small;
  }
}

.top-bar__box--right {
  justify-content: flex-end;
}

.top-bar__menu {

}

.top-bar__menu-item {
  font-size: $g-size-12;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  font-weight: 700;
  padding-right: 3rem;
  display: flex;
  color: $g-color-text;
  height: 100%; // for a to be 100% of height
  transition: .3s all;
  align-items: center;
  position: relative; // for submenu
  white-space: nowrap;

  .top-bar--custom & {
    color: $g-color-white;

    &:hover {
      color: $g-color-white;
    }
  }

  &:hover {
    color: $g-color-green;
  }

  .icon--arrow-down {
    font-size: $g-size-10;
    margin-top: -0.2rem;
  }
}

.top-bar--custom a.top-bar__menu-item:hover {
  opacity: 0.8;
}

.top-bar__menu-item--right {
  padding-left: 3rem;
  padding-right: 0;
}

.top-bar__toggler {
  width: 24px;
  height: 24px;
}

.top-bar__toggler--shape {
  position: relative;
  //top: 50%;
  z-index: 99;
  //transform: translateY(-0.4rem);

  span {
    width: 24px;
    height: 2px;
    background: $g-color-white;
    transition: .3s all;
    border-radius: 0.6rem;
    display: block;
    position: absolute;

    .main-nav-is-opened &,
    .top-bar--normal & {
      background: $g-color-black;
    }
  }

  span:nth-child(1) {
    left: 0;
    top: 0;
  }

  span:nth-child(2) {
    left: 0;
    top: 10px;
  }

  .main-nav-is-opened & span:nth-child(1),
  &.top-bar__toggler--cross span:nth-child(1) {
    transform: rotate(-45deg);
    left: 0px;
    top: 7px;
  }

  .main-nav-is-opened & span:nth-child(2),
  &.top-bar__toggler--cross span:nth-child(2) {
    transform: rotate(45deg);
    top: 7px;
  }
}

.top-bar__toggler--bg-text {
  span {
    background: $g-color-text;
  }
}

.top-bar__call-icon {
  width: 1.3rem;
  transform: rotate(-90deg);

  .phone-body {
    fill: $g-color-white;
  }

  .phone-signal,
  .phone-signal2 {
    fill: $g-color-white;
  }
}

.top-bar__call-icon--text {
  .phone-body {
    fill: $g-color-text;
  }

  .phone-signal,
  .phone-signal2 {
    fill: $g-color-text;
  }
}

.top-bar__logo-circle {
  position: relative;
  top: 1rem;

  @media all and (min-width: $g-break-1) {
    top: 2rem;
  }

  img {
    height: 6rem;

    @media all and (min-width: $g-break-1) {
      height: 8.3rem;
    }
  }
}

.top-bar__logo img {
  height: 0.85rem;
}

.top-bar__logo--header {
  margin-top: 0.5rem; // cosmetic
}

.top-bar__menu-icon {
  height: 1.4rem;
  display: inline-flex;
  margin: 0 0.6rem;
}

.top-bar__menu-icon--cart {
  position: relative;
  top: -0.1rem;
}

.top-bar__menu-icon--cart-mobile {
  height: 2rem;
}

.top-bar__menu-sub {
  opacity: 0;
  box-shadow: 6px 6px 18px 2px rgba(0, 0, 0, 0.09);
  list-style: none;
  background: $g-color-white;
  position: absolute;
  top: 80%;
  right: 0;
  transition: .3s all;
  padding: 0.5rem 0;
  z-index: 10;
  pointer-events: none;
  color: $g-color-gray3;

  a {
    padding: 0.75rem 1.5rem;
    white-space: pre;
    transition: .3s all;
  }

  a:hover {
    color: $g-color-text;
  }

  .top-bar__menu-item:hover & {
    opacity: 1;
    pointer-events: initial;
  }
}
