@import "../variables";

//
// LAYOUT 
// used for checkout page
//

.layout {

  @media all and (min-width: $g-break-1) {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

// used as container for two parts (left, right)
.layout__main {
  display: flex;
  flex-direction: column;


  @media all and (min-width: $g-break-1) {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
}

.layout__top {
  width: 100%;
}

.layout__container {
  padding: 0 4%;

  @media all and (min-width: $g-break-1) {
    padding: 0 10%;
  }

  @media all and (min-width: $g-break-2) {
    padding: 0 10%;
  }
}

.layout__container--half {
  padding: 0 4%;

  @media all and (min-width: $g-break-1) {
    padding: 0 5%;
  }

  @media all and (min-width: $g-break-2) {
    padding: 0 5%;
  }
}

.layout__left,
.layout__right {
  //padding: 0 ($g-container-padding / 2);
  overflow-y: auto;
  height: 100%;

  @media all and (min-width: $g-break-1) {
    flex: 0 1 50%;
  }
}

.layout__left {
  border-right: 0.125rem solid $g-color-gray2;
}

.layout__wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}