@import "../variables";

//
// MAIN NAV
//

.main-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  pointer-events: none;
  display: none;
  overflow-y: scroll;
  background: $g-color-white;

  @media all and (min-width: $g-break-2) {
    overflow: hidden;
  }
}

.main-nav--opened {
  pointer-events: initial;
  $initialDelay: 0.3s;

  .nav-item {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    visibility: inherit;
    opacity: 1;
  }
}

.main-nav__menu {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 auto;
  cursor: pointer;

  .nav-item {
    padding: 1.1rem $g-container-padding-large;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    @extend .icon--arrow-right;

    &::before {
      font-family: $g-font-icons;
      position: absolute;
      right: $g-container-padding-large;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
    }

    @media all and (min-width: $g-break-1) {
      padding: 1.2rem 0;
    }
  }

  .nav-item__text {
    display: none;
  }
}

.main-nav__top-bar {
  border-bottom: 0.0625rem solid $g-color-border;
}

.main-nav__bottom-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  .button {
    flex: 1 1 auto;
  }

  .button:nth-child(1) {
    margin-right: 1rem;
  }
}