@import "../variables";

//
// BUTTON COMPONENT
//

.button {
  font: inherit;
  padding: 0.8rem 2.2rem;
  background: rgba(255,255,255,0.8);
  border: 0.125rem solid $g-color-text;
  color: $g-color-text;
  text-transform: uppercase;
  font-weight: 700;
  transition: .3s all;
  text-align: center;
  letter-spacing: 0.25rem;
  cursor: pointer;
  font-size: $g-size-12;
  line-height: inherit;
  -webkit-appearance: none;

  &:hover {
    background: $g-color-green;
    color: $g-color-white;
    border-color: $g-color-green;
  }

  @media all and (min-width: $g-break-1) {
    min-width: 9rem;
  }
}

.button--small {
  padding: 0.6rem 2.5rem;
  font-size: $g-size-10;

  @media all and (min-width: $g-break-1) {
    font-size: $g-size-12;
  }
}

// NOT USED
.button--medium {
  padding: 0.8rem 1.2rem;
  font-size: $g-size-12;
  letter-spacing: 0.2em;
  min-width: auto;
}

.button--green{
  color: $g-color-white;
  background: transparentize($g-color-green, 0.2);
  border-color: transparent;

  &:hover {
    background: transparentize(darken($g-color-green, 20%), 0.2);
  }
}

// For page message
// Color: WHITE (green)
// BG: Transparent (white)
.button--white-transparent-green {
  color: $g-color-white;
  background: transparent;
  border-color: $g-color-white;

  &:hover {
    background: $g-color-white;
    color: $g-color-green;
  }
}

// Color: inherit
// BG: transparent (gray2)
// Border: gray2
.button--black-transparent-gray2   {
 background: transparent;
  border: 0.125rem solid $g-color-gray2;

  &:hover {
    color: $g-color-black;
    background: $g-color-gray2;
    border-color: $g-color-gray2;
  }
}

.button--black-gray2 {
  color: $g-color-text;
  background: $g-color-gray2;
  border: 0.125rem solid $g-color-gray2;
}