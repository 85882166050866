@import "../variables";

//
// TOOLTIP COMPONENT
// used by tippy.js
//

.tippy-backdrop {
  background-color: $g-color-green-light;
  color: $g-color-white;
}

.tooltip-symbol {
  padding: 0 0.2rem;
  cursor: pointer;
}