@import "../variables";

//
// SECTION COMPONENT
//

.section {
  padding: 2rem 0;

  @media all and (min-width: $g-break-1) {
    padding: 4rem 0;
  }
}

.section--half {
  padding: 2rem 0;
}

.section--double {
  padding: 4rem 0;

  @media all and (min-width: $g-break-1) {
    padding: 8rem 0;
  }
}

.section--top-0 {
  padding-top: 0;
}

.section--bottom-0 {
  padding-bottom: 0;
}

.section--bg-1 {
  background: $g-color-gray1;
}

.section--bg-2 {
  background: $g-color-gray2;
}